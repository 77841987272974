/**
 * Styles
 */
//  import './style.scss';

 /**
  * External Dependencies
  */
  import React, { Component } from 'react';
  import PageWrap from '../../components/page-wrap';
 
  /**
   * Internal Dependencies
   */
  import AsyncComponent from '../../components/async-component';
  
  /**
   * Component
   */
  class AssignTask extends Component {
      render() {
          return (
                     <PageWrap>
                          <AsyncComponent component={ () => import( './content' ) } />
                      </PageWrap>
          );
      }
  }
  
  export default AssignTask;
  