/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { withRouter, HashRouter, Route } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

/**
 * Internal Dependencies
 */
import './components/animated-route';
import reducers from './reducers';
import Routes from './Routes';
import PageYaybar from './components/page-yaybar';
import PageNavbar from './components/page-navbar';
import PageToasts from './components/page-toasts';
import Cookies from "js-cookie";

import { updateAuth as actionUpdateAuth } from './actions';

const createStoreWithMiddleware = applyMiddleware()( createStore );
const $html = window.jQuery( 'html' );
const $body = window.jQuery( 'body' );

/**
 * Component PageWrap
 */
class PageWrap extends Component {
    constructor( props ) {
        super( props );

        this.maybeCheckAuth = this.maybeCheckAuth.bind( this );
        this.maybeUpdateGlobalSettings = this.maybeUpdateGlobalSettings.bind( this );
        this.maybeScrollPageToTop = this.maybeScrollPageToTop.bind( this );
    }

    componentDidMount() {
        this.maybeCheckAuth();
        this.maybeUpdateGlobalSettings();

        this.handleRedirect = (event) => {
            // if (event.data && event.data.url) {
                window.location.href="https://hrm.esenseit.in/#/employee-performance"
                // window.location.href="http://localhost:5001/#/employee-performance"
            // }
        };

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
              navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then((registration) => {
                  console.log('Service Worker registered with scope:========', registration.scope);
                })
                .catch((error) => {
                  console.error('Service Worker registration failed:', error);
                });
            });
            navigator.serviceWorker.addEventListener('message', this.handleRedirect);
         
        }



          if ('serviceWorker' in navigator) {
            // Use dynamic import
            import('firebase/messaging').then(({ onMessage }) => {
                import('../src/pages/firebase').then(({ messaging }) => {
                // import('../firebase').then(({ messaging }) => {
                    // Your logic here to work with getToken and messaging
                    console.log('Firebase Messaging initialized.==========');
    
                    const listenForMessages = () => {
                        console.log("Message======");
                      onMessage(messaging, (payload) => {
                        console.log('Message received. ', payload,messaging);
                       
                        // Optionally show a browser notification or update your UI
                        new Notification(payload.notification.title, {
                          body: payload.notification.body,
                          icon: payload.data.icon,
                          badge: payload.data.icon,
                          data: {
                            url: "https://hrm.esenseit.in/#/employee-performance", // Add URL to data field
                            // url: "http://localhost:5001/#/employee-performance", // Add URL to data field
                          },
                        });
                      });
                    }
    
                    // Call requestPermission here
                    listenForMessages();
    
                }).catch(error => {
                    console.error('Error loading Firebase configuration:', error);
                });
            }).catch(error => {
                console.error('Error loading Firebase Messaging:', error);
            });
        } else {
            console.log('Service Worker is not supported in this browser.');
        }
    }
    componentWillUnmount() {
        console.log("commmmdiimountNewwwwwwwwww===");
        // Cleanup event listeners when the component is unmounted
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.removeEventListener('message', this.handleRedirect);
        }
    }
    componentDidUpdate( prevProps ) {
        this.maybeCheckAuth( prevProps );
        this.maybeUpdateGlobalSettings( prevProps );
        this.maybeScrollPageToTop( prevProps );
    }

    isSignPage( check ) {
        if ( ! check ) {
            check = window.location.hash.replace( /^#/g, '' );
        }

        return check === '/sign-in' || check === '/sign-up' || check ==='/forgot-password' || check ==='/unique-code';
    }
    maybeCheckAuth( prevProps ) {
        const {
            auth,
            updateAuth,
            history,
        } = this.props;

        let newRedirect = false;
        // var reURL = Cookies.get("token2")
        //     reURL = JSON.parse(reURL);
        //  if(reURL["data"][0]["type"]=="admin"){
        //      var shashank_URL = "/grid"
        //  }else{
        //     var shashank_URL = "/calendar"
        //  }
        const referrerUrl = auth.referrer && ! this.isSignPage( auth.referrer ) ? auth.referrer : '/';

        // Redirect from Auth page.
        if ( this.isSignPage() && auth.token2 ) {
            newRedirect = referrerUrl;

            // Redirect to Auth page.
        } else if ( ! this.isSignPage() && ! auth.token2 ) {
            newRedirect = '/sign-in';

            // Check if use logged out or logged in.
        } else if ( prevProps && auth.token2 !== prevProps.auth.token2 ) {
            newRedirect = auth.token2 ? referrerUrl : '/sign-in';
        }

        // Redirect.
        if ( newRedirect ) {
            updateAuth( {
                referrer: window.location.hash.replace( /^#/g, '' ),
            } );
            history.push( newRedirect );
        }

    }



    maybeUpdateGlobalSettings( prevProps ) {
        const { settings } = this.props;

        // night mode.
        if (Cookies.get('night_mode') == "true") {

            $html.addClass('rui-night-mode');

            // eslint-disable-next-line no-unused-expressions
            import('./style-night.scss');

        }
        else {
            $html.removeClass('rui-night-mode');
        }
        // if ( prevProps && prevProps.settings.night_mode !== settings.night_mode ) {
        //     if ( settings.night_mode ) {
        //         $html.addClass( 'rui-night-mode' );

        //         // eslint-disable-next-line no-unused-expressions
        //         import( './style-night.scss' );
        //     } else {
        //         $html.removeClass( 'rui-night-mode' );
        //     }
        // }
        // if ( ! prevProps && settings.night_mode ) {
        //     $html.addClass( 'rui-night-mode' );

        //     // eslint-disable-next-line no-unused-expressions
        //     import( './style-night.scss' );
        // }

        // spitlight mode.
        if ( prevProps && prevProps.settings.spotlight_mode !== settings.spotlight_mode ) {
            if ( settings.spotlight_mode ) {
                $body.addClass( 'rui-spotlightmode' );
            } else {
                $body.removeClass( 'rui-spotlightmode' );
            }
        }
        if ( ! prevProps && settings.spotlight_mode ) {
            $body.addClass( 'rui-spotlightmode' );
        }

        // section lines.
        if ( prevProps && prevProps.settings.show_section_lines !== settings.show_section_lines ) {
            if ( settings.show_section_lines ) {
                $body.addClass( 'rui-section-lines' );
            } else {
                $body.removeClass( 'rui-section-lines' );
            }
        }
        if ( ! prevProps && settings.show_section_lines ) {
            $body.addClass( 'rui-section-lines' );
        }

        // sidebar small.
        if ( prevProps && prevProps.settings.sidebar_small !== settings.sidebar_small ) {
            if ( settings.sidebar_small ) {
                $body.addClass( 'yay-hide' );
            } else {
                $body.removeClass( 'yay-hide' );
            }
        }
        if ( ! prevProps && settings.sidebar_small ) {
            $body.addClass( 'yay-hide' );
        }
    }

    maybeScrollPageToTop( prevProps ) {
        if ( this.props.location.pathname !== prevProps.location.pathname ) {
            window.scrollTo( {
                top: 0,
                behavior: 'smooth',
            } );
        }
    }

    render() {
        const {
            auth,
            location,
        } = this.props;
        var height = window.innerHeight;
        var width = window.innerWidth;
        console.log("height",height);
        if(width > 768){
          document.getElementById("app").style.height = height + "px";
          document.getElementById("app").style.overflow = "hidden"
        }
        else{
          document.getElementById("app").style.height = "auto";
          document.getElementById("app").style.overflow = "auto"
        }
        // document.getElementById("app").style.height = height + "px";
        // document.getElementById("app").style.overflow = "hidden"
        return (
            <TransitionGroup>
                <PageToasts />
                <Route>
                    { auth.token2 ? (
                        <>
                         <Route component={ PageNavbar } />
                            <Route component={ PageYaybar } />

                        </>
                    ) : '' }
                </Route>
                <CSSTransition
                    key={ location.pathname }
                    timeout={ 300 }
                    classNames="rui-router-transition"
                    unmountOnExit
                >
                    <Routes location={ location } />
                </CSSTransition>
            </TransitionGroup>
        );
    }
}

const PageWrapWithState = connect( ( { auth, settings } ) => (
    {
        auth,
        settings,
    }
), { updateAuth: actionUpdateAuth } )( withRouter( PageWrap ) );

/**
 * Component App
 */
class App extends Component {
    constructor( props ) {
        super( props );

        // create redux store.
        this.store = createStoreWithMiddleware( reducers );
    }

    render() {
        return (
            <Provider store={ this.store }>
                <HashRouter>
                    <PageWrapWithState />
                </HashRouter>
            </Provider>
        );
    }
}

export default App;
